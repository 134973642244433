define("discourse/plugins/discourse-animated-avatars/initializers/animated-avatars", ["exports", "@ember/runloop", "discourse/lib/plugin-api", "discourse/lib/utilities"], function (_exports, _runloop, _pluginApi, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let animatedImages = [];
  let allowAnimation = true;
  function userCardShown() {
    return document.querySelector("#user-card.show");
  }

  // Only play when the user card is not shown
  function playAvatarAnimation(img) {
    if (!userCardShown()) {
      play(img);
    }
  }
  function play(img) {
    if (img && allowAnimation) {
      let animatedImg = img.src.replace(/\.png$/, ".gif");
      if (animatedImg !== img.src) {
        img.src = img.src.replace(/\.png$/, ".gif");
        animatedImages.push(img);
      }
    }
  }
  function pause(img) {
    if (img) {
      let animatedImg = img.src.replace(/\.gif$/, ".png");
      if (animatedImg !== img.src) {
        img.src = img.src.replace(/\.gif$/, ".png");
      }
      animatedImages = animatedImages.filter(item => item !== img);
    }
  }
  function pauseAll() {
    let resumable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    animatedImages?.forEach(img => {
      img.src = img.src.replace(/\.gif$/, ".png");
    });

    // pause all either due to a resumable event (temporarily prevent any animation event to fire until event is over)
    // or we are stopping all animation, and will listen for future events to fire new animations
    if (resumable) {
      allowAnimation = false;
    } else {
      animatedImages = [];
    }
  }
  function resumeAll() {
    allowAnimation = true;
    animatedImages?.forEach(img => {
      img.src = img.src.replace(/\.png$/, ".gif");
    });
  }
  function getPauseAnimateAvatarEventFn() {
    let eventParentSelector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let avatarSelector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return e => {
      const target = eventParentSelector != null ? e.target.closest(eventParentSelector) : e.target;

      // We are still hovering over a parent target, do not pause
      const center = document.elementsFromPoint(e.clientX, e.clientY);
      if (center.some(ele => ele === target)) {
        return;
      }
      const images = avatarSelector != null ? target?.querySelectorAll(avatarSelector) : [target];
      images?.forEach(img => {
        pause(img);
      });
    };
  }
  function getAnimateAvatarEventFn() {
    let eventParentSelector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let avatarSelector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return e => {
      const target = eventParentSelector != null ? e.target.closest(eventParentSelector) : e.target;
      const images = avatarSelector != null ? target?.querySelectorAll(avatarSelector) : [target];
      images?.forEach(img => {
        playAvatarAnimation(img);
      });
    };
  }
  var _default = _exports.default = {
    name: "animated-avatars",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        //disable if prefers reduced motion
        if ((0, _utilities.prefersReducedMotion)()) {
          return;
        }
        window.addEventListener("blur", this.blurEvent);
        window.addEventListener("focus", this.focusEvent);
        api.customUserAvatarClasses(user => {
          if (user?.animated_avatar != null) {
            return ["animated-avatar"];
          }
          return [];
        });
        api.includePostAttributes("animated_avatar");
        api.addPostClassesCallback(attrs => {
          if (attrs?.animated_avatar != null) {
            return ["animated-avatar"];
          }
          return [];
        });
        api.onAppEvent("user-card:after-show", () => {
          // Allow render
          (0, _runloop.next)(() => {
            // Do not animate other images
            pauseAll();

            // Play on user card with fewer conditions
            play(document.querySelector("#user-card img.animated-avatar"));
          });
        });

        // Always animated
        const siteSettings = api.container.lookup("service:site-settings");
        if (siteSettings.animated_avatars_always_animate) {
          api.reopenWidget("post", {
            didRenderWidget() {
              if (this.attrs.animated_avatar && this.siteSettings.animated_avatars_always_animate) {
                document.querySelectorAll(".animated-avatar .main-avatar img.avatar").forEach(img => {
                  img.src = img.src.replace(/\.png$/, ".gif");
                });
              }
            }
          });
        }

        // Only animate on hover, and keyboard focus events
        else {
          api.onAppEvent("keyboard:move-selection", _ref => {
            let {
              articles,
              selectedArticle
            } = _ref;
            articles?.forEach(a => {
              if (a.classList.contains("animated-avatar")) {
                pause(a.querySelector(".main-avatar img.avatar"));
              }
            });
            if (selectedArticle.classList.contains("animated-avatar")) {
              playAvatarAnimation(selectedArticle.querySelector(".main-avatar img.avatar"));
            }
          });
          api.reopenWidget("post", {
            mouseOver: getAnimateAvatarEventFn(".animated-avatar", ".main-avatar>.avatar"),
            mouseOut: getPauseAnimateAvatarEventFn(".animated-avatar", ".main-avatar>.avatar")
          });
        }
      });
    },
    blurEvent() {
      pauseAll(true);
    },
    focusEvent() {
      resumeAll();
    },
    teardown() {
      window.removeEventListener("blur", this.blurEvent);
      window.removeEventListener("focus", this.focusEvent);
    }
  };
});