define("discourse/plugins/discourse-animated-avatars/app/helpers/animated-bound-avatar", ["exports", "discourse/helpers/bound-avatar", "discourse/lib/utilities", "discourse-common/lib/helpers"], function (_exports, _boundAvatar, _utilities, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.htmlHelper)((user, size) => {
    const avatar = (0, _boundAvatar.default)(user, size);
    if (user.animated_avatar != null && !(0, _utilities.prefersReducedMotion)()) {
      return avatar.toString().replace(/\.png/, ".gif");
    }
    return avatar;
  });
});